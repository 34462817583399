
import { defineComponent, ref, onMounted, watch } from 'vue';
import { useRoute } from 'vue-router';
import axios, { AxiosError } from 'axios';
import useAlert from "@/composables/Alert"
import router from '@/router';
import { campHandleEveryoneIsTrue, campHandleEmailVerification, campHandleCNPJ, campHandleBrPhoneNumber } from '@/composables/DataValidation';
import {
  CampHeader,
  CampFormHeader,
  CampFormRackSubmitBtn,
} from '@/components';
import { useLoaderStore} from "@/store/LoaderStore"

import { IStoreData, IProfile, TInitApprovalOptions, initApprovalOptions, UF_LIST } from './PostPage.vue';

interface ICompanyResponse {
  data: {
    error: boolean,
    message: string,
    data: IStoreData
    errorCode: string
  },
  status: number
}

function checkEmptyFields(obj: IStoreData): boolean {
  for (let key in obj) {
    if (obj[key] === "") {
      return true;
    }
  }
  return false;
}

export default defineComponent({
  name: "StorePut",
  components: {
    CampHeader,
    CampFormHeader,
    CampFormRackSubmitBtn,
  },
  setup() {
    /**
     * NOTA:
     * 
     * O valor da constante booleana, "IS_PERCENTAGE_POINT_STORE_MODERATOR", deve ter o mesmo valor da propriedade
     * "IS_PERCENTAGE_POINT_STORE_MODERATOR" definida na API, disponível em "pontuei-api/app/Models/Sale/Sale.php"
     */
    const IS_PERCENTAGE_POINT_STORE_MODERATOR = false;

    const route = useRoute();
    
    const queryObject = ref<{
      isLoading: boolean,
      noError: boolean,
      data:  null | IStoreData
    }>({
      isLoading: false,
      noError: false,
      data: null
    })
    const profileField = ref<IProfile[] | null>(null)
    const groupField = ref<any[] | null>(null)
    const approvalOptions = ref<TInitApprovalOptions>([...initApprovalOptions])
    const isMainCNPJGroup = ref<boolean>(false)

    const useStore = useLoaderStore()
    
    onMounted(async () => {
      useStore.open()
      try {
        const [result1, result2, result3] = await Promise.all([
          axios.get(`/api/getStoreView/${id}`),
          axios.get(`/api/getProfile`),
          axios.get(`/api/getGroup`),
        ])

        const { data } = result1
        profileField.value = [{ id: "", name: "" }, ...result2.data.data]
        groupField.value = [{ id: "", name: "" }, ...result3.data.data]
        if(!data.error) {
          if(typeof data?.data?.reward_the_moderator === "number")
            data.data.reward_the_moderator = !!data.data.reward_the_moderator
          queryObject.value.data = data.data
          if(queryObject.value.data?.uf && queryObject.value.data.uf.toUpperCase() === "UF")
            queryObject.value.data.uf = ""
          groupField.value.forEach(group => {
            if(group.main_cnpj === queryObject.value.data?.cnpj) {
              isMainCNPJGroup.value = true
            }
          })
        }
      } catch (error) {
        return router.push("/404")
      } finally {
        useStore.close()
      }
    })

    const { id } = route.params;
    const { showTimeAlert } = useAlert()

    /** Warning of unfilled fields */
    const isRequiredField = ref(false)
    const isInvalidFieldWarning = ref(false)
    const isInvalidField = ref<{ 
      cnpj: boolean,
      mail: boolean,
      phoneNumber: boolean
     }>({
      cnpj: false,
      mail: false,
      phoneNumber: false
    })

    /** Validate CNPJ */
    watch(() => queryObject.value.data?.cnpj, () => {
      if(queryObject.value.data?.cnpj) {
        isInvalidField.value.cnpj = !campHandleCNPJ(queryObject.value.data.cnpj)
      }
    })

    /** Validate email */
    watch(() => queryObject.value.data?.email, () => {
      if(queryObject.value.data?.email) {
        isInvalidField.value.mail = !campHandleEmailVerification(queryObject.value.data.email)
      }
    })

    /** Validate phone number */
    watch(() => queryObject.value.data?.telephone, () => {
      if(queryObject.value.data?.telephone) {
        isInvalidField.value.phoneNumber = !campHandleBrPhoneNumber(queryObject.value.data.telephone)
      }
    })

    /** Reset reward the moderator*/
    watch(() => queryObject.value.data?.sale_status_default, () => {
      if(!queryObject.value.data)
        return
      if(queryObject.value.data.sale_status_default == 2)
        queryObject.value.data.reward_the_moderator = false
    })

    const clearWarning = () => (isRequiredField.value = false, isInvalidFieldWarning.value = false)

    async function onSubmitForm() {
      isInvalidFieldWarning.value = false
      isRequiredField.value = false
      const { data } = queryObject.value
      if(!data) {
        return
      }
      const { id_profile, id_group, percent_value, code_regional_internal_salesman, reward_the_moderator, ...requiredFields } = data
      if(checkEmptyFields(requiredFields)) {
        return isRequiredField.value = true
      }
      if(campHandleEveryoneIsTrue(isInvalidField.value)) {
        isInvalidFieldWarning.value = true
        return isRequiredField.value = true
      }
      useStore.open()
      try {
        const { data } = queryObject.value
        if(data) {
          const { telephone, cnpj, ...res } = data
          const putData = {
            ...res,
            reward_the_moderator,
            telephone: campHandleBrPhoneNumber(telephone, "unformatted"),
            cnpj: campHandleCNPJ(cnpj, "unformatted"),
            id_profile: id_profile ? id_profile : null,
            id_group: id_group && !isMainCNPJGroup.value ? id_group : null,
            percent_value: percent_value? parseInt(percent_value.toString().replace('%', '')) : null
          }
          const result: ICompanyResponse = await axios.put(`/api/putStore/${data.id}`, putData)
          showTimeAlert("Atualizado com sucesso!")
          return router.push("/lojas")
        }
      } catch (error) {
        if(error instanceof AxiosError) {
            showTimeAlert(error.response?.data.message, "error")
        } else {
          showTimeAlert("Algo deu errado!", "error")
        }
        useStore.close()
      }
    }

    return {
      IS_PERCENTAGE_POINT_STORE_MODERATOR,
      queryObject,
      isRequiredField,
      isInvalidField,
      isInvalidFieldWarning,
      onSubmitForm,
      profileField,
      groupField,
      approvalOptions,
      clearWarning,
      isMainCNPJGroup,
      UF_LIST,
    }
  }
})
